import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container, Row, Col } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Latest from "../../components/latest/LatestList";
import Hero from "../../components/common/Hero"; 
import SEO from "../../components/common/SEO";

class Articles extends Component {
  render() {
      return (
        <Layout>          
          <SEO post={{
          title: "Facts First Latest News And Articles",
          description: "We share the latest news and articles at Facts First"
          }} />
          <Hero title="News" />  
          <Helmet bodyAttributes={{ class: "articles-page" }} />
          <Container>
            <Row className={"latest"}>
              <Col>
                <Latest />
              </Col>
            </Row>           
          </Container>
          <Newsletter />
        </Layout>
      ); 
  }
}

export default Articles; 